<template>
  <v-row no-gutters class="d3-regions-map">
    <svg :height="height" :width="width">
      <g class="full" transform="translate(10,10)">
        <g id="map"></g>
        <g id="legend"></g>
      </g>
    </svg>
  </v-row>
</template>

<script>
import {
  geoAlbers,
  select,
  geoPath,
  interpolateRdYlGn,
  scaleLinear,
  min,
  max,
  format,
} from 'd3'

import mapJson from '../assets/russia.json'
import * as topojson from 'topojson-client'
import { legend } from '../d3/colorLegend'
import d3PlotMixin from './d3PlotMixin'
import d3WatchAndMountMixin from './d3WatchAndMountMixin'

import { mapActions } from 'vuex'

export default {
  mixins: [d3PlotMixin, d3WatchAndMountMixin],
  props: {
    data: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    events: {
      type: Object,
      required: false,
    },
  },
  computed: {
    color() {
      return scaleLinear()
        .interpolate(() => d => interpolateRdYlGn(1 - d))
        .domain([min(this.data.map(d => +d.value)) || 0, max(this.data.map(d => +d.value)) || 1])
    },
    svg() {
      return select(this.$el).select('svg g')
    },
    width() {
      return this.options.width
    },
    height() {
      return this.options.height
    },
    mapSvg() {
      return this.svg.select('g#map')
    },
    mapLegend() {
      return this.svg.select('g#legend')
    },
    regions() {
      const regions = topojson.feature(mapJson, mapJson.objects['russia.2003']).features
      regions.forEach(region => {
        const f = this.data.find(r => r.iso_code === region.properties.iso_3166_2)
        region.value = f ? +f.value : 0
        region.regionId = f ? f.regionId : null
      })
      return regions
    },
    projection() {
      const { width, height, options } = this
      const { scale } = options
      return geoAlbers()
        .rotate([-105, 0])
        .center([-10, 65])
        .parallels([52, 64])
        .scale(width * scale)
        .translate([width / 2, height / 2])
    },
    pathGenerator() {
      return geoPath().projection(this.projection)
    },
    legendNode() {
      return legend({
        color: this.color,
        title: 'Потерянные годы',
        width: 240,
        opacity: this.options.opacity.default,
        tickFormat: '.2~',
        ticks: 3,
      })
    },
  },
  methods: {
    ...mapActions({
      setTooltip: 'tt/setTooltip',
    }),
    createMap() {
      const {
        color,
        regions,
        pathGenerator,
        mapSvg,
        receiveStrokeColor,
        receiveOpacity,
        receiveStrokeWidth,
        onMouseOver,
      } = this
      mapSvg
        .selectAll('path')
        .data(regions, d => d.regionId)
        .join(
          enter =>
            enter
              .append('path')
              .attr('class', d => `country can-clouded region-${d.properties.fid}`)
              .attr('fill', d => color(d.value ? +d.value : 0))
              .attr('fill-opacity', d => receiveOpacity(d))
              .attr('stroke', d => receiveStrokeColor(d))
              .attr('stroke-width', d => receiveStrokeWidth(d))
              .attr('d', pathGenerator)
              .on('mouseover.internal', function (d) {
                onMouseOver(d, this)
              })
              .on('mouseout.internal', () => this.onMouseOut())
              .call(this.bindEvents, this.events),
          update =>
            update
              .transition()
              .duration(this.animationDuration)
              .attr('fill', d => color(d.value ? +d.value : 0))
              .attr('fill-opacity', d => receiveOpacity(d))
              .attr('stroke', d => receiveStrokeColor(d))
              .attr('stroke-width', d => receiveStrokeWidth(d))
        )
    },
    onMouseOver(d, elem) {
      this.$emit('map-hover', d.regionId)
      this.setTooltip({
        show: true,
        top: window.pageYOffset + elem.getBoundingClientRect().top,
        left:
          window.pageXOffset +
          elem.getBoundingClientRect().left +
          elem.getBoundingClientRect().width / 2,
        title: d.properties.reg,
        text: `Потерянные годы: ${format('.3~')(d.value)}`,
      })
    },
    onMouseOut() {
      this.$emit('map-hover', null)
      this.setTooltip({
        show: false,
      })
    },
    createLegend() {
      const { mapLegend, legendNode, height } = this
      mapLegend
        .attr('transform', `translate(0,${height - 60})`)
        .select('svg')
        .remove()

      mapLegend.node().append(legendNode)
    },
    isSelected(d) {
      return this.options.selectedIds.includes(d.regionId)
    },
    isHovered(d) {
      return this.options.hoveredIds.includes(d.regionId) || this.options.hoveredId === d.regionId
    },
    receiveStrokeColor(d) {
      return this.isSelected(d) ? this.options.strokeColor : this.options.strokeColor
    },
    receiveStrokeWidth(d) {
      return this.isSelected(d) ? '2' : '0.5'
    },
    receiveOpacity(d) {
      const { opacity, hoveredId } = this.options
      if (hoveredId) {
        if (hoveredId === d.regionId) {
          return opacity.hover
        } else if (!this.isSelected(d)) {
          return opacity.otherOnHover
        }
      }
      if (this.isSelected(d)) {
        return opacity.selected
      }
      return opacity.default
    },
    render() {
      if (!this.data) return
      this.createMap()
      this.createLegend()
    },
  },
}
</script>

<style scoped>
#map >>> path:hover {
  cursor: pointer;
}
</style>
