export default {
  mounted() {
    this.render()
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.$nextTick(() => this.render())
      },
    },
    options: {
      deep: true,
      handler() {
        this.$nextTick(() => this.render())
      },
    },
  },
}
